import pallet from "./color-pallets/site-colors";

const colors = {
  primary: "#C6AC69",
  modalBackdrop: `${pallet.neutrals[9]}90`,

  whiteTransparent: pallet.whiteTransparent[9],

  text: "#252525",
  textMuted: pallet.neutrals[5],
  textLight: pallet.neutrals[1],
  textHeading: "#C6AC69",
  textNav: pallet.primary[4],

  light: pallet.neutrals[1],

  background: "white",
};

export default colors;

export const gradients = {
  primary: {
    backgroundImage: () => {
      return `linear-gradient(90deg, #8F5E25 0%, #FBF4A1 50%, #8F5E25 100%);`;
    },
  },
};

export const colorPallet = { ...pallet };
