import backgrounds from "./backgrounds";
import buttons from "./buttons";
import cards from "./cards";
import colors, { gradients } from "./colors";
import cta from "./cta";
import forms from "./forms";
import { Hero } from "./hero";
import layout from "./layouts";
import modals from "./modals";
import spacing from "./spacing";
import typeography, {
  headingStyles,
  textStyles,
  lineHeights,
} from "./typeography";

const theme = {
  breakpoints: ["768px", "1025px", "1290px"],
  header: {
    zIndex: "999",

    position: "sticky",
    top: "0",
    left: "0",
    width: "100%",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    height: ["50px", "70px"],
    //overflow: "hidden",
  },
  ...spacing,
  ...typeography,
  ...lineHeights,
  colors: { ...colors },

  text: textStyles,
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 2,
    },
    ...headingStyles,
    ...textStyles,
    blockQuote: {
      display: "block",
      borderWidth: "2px 0",
      borderStyle: "solid",
      borderColor: "#eee",
      padding: "1.5em 0 0.5em",
      margin: "1.5em 0",
      position: "relative",
      "::before": {
        content: "'\\201C'",
        position: "absolute",
        top: "0em",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        width: "3rem",
        height: "2rem",
        font: "6em/1.08em 'PT Sans', sans-serif",
        color: "primary",
        textAlign: "center",
      },
    },
    ".video-container": {
      overflow: "hidden",
      position: "relative",
      height: 400,
      iframe: {
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
        borderRadius: "4px",
      },
    },
  },
  gradients: { ...gradients },
  ...layout,
  ...buttons,
  ...cards,
  ...forms,
  hero: Hero,
  ...modals,
  cta: cta,

  ...backgrounds,
  quote: {
    variant: "backgrounds.primary.gradient.shadow",
  },

  links: {
    nav: {
      color: "primary",
      fontWeight: "body",
      fontSize: [4, 3],
      "&.active": {
        fontWeight: "Bold",
      },
      highlight: {
        variant: "buttons.primary",
      },
    },
  },
};

export default theme;
