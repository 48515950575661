import { gradients } from "./colors";

const buttons = {
  buttons: {
    default: {
      display: "block",
      py: 2,
      px: 3,
      textAlign: "center",
      fontSize: [3, 2, 2],
      borderRadius: "4px",
      textTransform: "uppercase",
      textDecoration: "none",
      fontWeight: "button",
    },
    fixedSide: {
      variant: "buttons.default",
      fontSize: [1, null],
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      zIndex: "999",
      position: "fixed",
      top: "50%",
      right: "-40px",
      transform: "rotate(-90deg)",
    },
    primary: {
      variant: "buttons.default",
      //...gradients.primary,
      color: "white",
      bg: "primary",
      borderImage:
        "linear-gradient(90deg, #8F5E25 0%, #FBF4A1 50%, #8F5E25 100%)",
      //boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      outline: {
        variant: "buttons.default",
        color: "primary",
        //border: (theme) => `2px solid ${theme.colors.primary}`,
        // border: "1px solid transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        borderImage:
          "linear-gradient(90deg, #8F5E25 0%, #FBF4A1 50%, #8F5E25 100%)",
        borderImageSlice: 1,
        bg: "transparent",
      },
      white: {
        variant: "buttons.default",
        bg: "white",
        color: "primary",
        boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      },
    },
    secondary: {
      white: {
        variant: "buttons.default",
        bg: "white",
        color: "secondary",

        boxShadow: "0px 4px 8px #998383, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      },
    },
    vimeo: {
      bg: "primary.gradient",

      fontSize: 5,
    },
  },
};

export default buttons;
