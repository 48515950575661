const modals = {
  modals: {
    primary: {
      backDrop: {
        zIndex: 9999,
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        bg: "modalBackdrop",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
        //pointerEvents: "none",
      },
      container: {
        width: ["90%", , "70%"],
        height: ["80%", , "90%"],
        overflow: "auto",
        position: "relative",
        bg: "white",
        p: 4,
        borderRadius: 4,
        h3: {
          variant: "styles.h3",
          marginBlock: ".5em",
          //color:
        },
      },
      close: {
        position: "absolute",
        top: 0,
        right: 0,
        color: "primary",
      },
    },
    dark: {
      backDrop: {
        variant: "modals.primary.backDrop",
      },
      container: {
        variant: "modals.primary.container",
        bg: "primary",
      },
      close: {
        variant: "modals.primary.close",
        color: "primaryLight",
      },
    },
  },
};

export default modals;
