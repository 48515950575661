const cta = {
  vertical: {
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "white",
    bg: "black",
  },
};

export default cta;
