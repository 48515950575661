import ColorPallet from "./color-pallet.interface";

const pallet: ColorPallet = {
  transparent: "transparent",
  black: "#000",
  white: "#ffffff",
  whiteTransparent: [
    "#FFFFFF10",
    "#FFFFFF20",
    "#FFFFFF30",
    "#FFFFFF40",
    "#FFFFFF50",
    "#FFFFFF60",
    "#FFFFFF70",
    "#FFFFFF80",
    "#FFFFFF90",
    "#FFFFFF99",
  ],
  //Gold
  primary: ["#C6AC69"],

  //Red
  secondary: ["#B82E30"],
  secondaryTransparent: [],
  //blue-gray
  neutrals: [
    "#F0F4F8",
    "#D9E2EC",
    "#BCCCDC",
    "#9FB3C8",
    "#829AB1",
    "#627D98",
    "#486581",
    "#334E68",
    "#243B53",
    "#102A43",
  ],

  //Dark
  supportingA: [],

  supportingB: [],
};

export default pallet;
