export const Hero = {
  background: {
    position: "absolute",
    top: 0,
    height: "560px",
    width: "100%",
    zIndex: -1,
  },
  contentWrapper: {
    width: ["100%", "80%"],
    height: ["80%", "60%"],
    justifyContent: "space-evenly",
    alignItems: ["center", null, null],
    display: "flex",
    color: "white",
    flexDirection: ["column", "column"],
  },
  h1: {
    display: "flex",
    color: "primary",
    flexDirection: ["column"],
    fontWeight: ["bold", "body"],
    fontSize: [5, 7],
    textTransform: "uppercase",
    alignItems: ["center"],
    span: { lineHeight: ["40px", "56px"] },
    textAlign: "center",
  },
  message: {
    mt: 3,
    width: ["80%", "80%"],
    fontWeight: ["light", "thin"],
    fontSize: [2, 4],
    textAlign: ["center"],
  },
};
